import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { StyledLargeImg, StyledImg } from "./common";
import logoWeb from "../images/Logo-Appscom.svg";
import logo from "../images/favicon.png";
import arrowDown from "../images/arrow-down.svg";
import segmentlyImage from "../images/segmently-image.png";
import aboutUsImage from "../images/about-us-image.png";

const Navbar = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fffbf8;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  padding: 15px 9%;
  box-shadow: 0px 4px 10px #5a65900f;
  z-index: 999;
  @media (max-width: 575px) {
    padding: 12px 25px;
    grid-template-columns: auto;
    justify-content: start;
  }
`;
const LogoContainer = styled.div`
  @media (max-width: 575px) {
    width: 40px;
  }
`;
const PageSection = styled.div`
  display: flex;
  justify-content: end;
  gap: 40px;
  flex-direction: row;
  align-items: start;
  @media (max-width: 575px) {
    display: none;
  }
`;
const StyledLink = styled(Link)`
  color: #2d2d51;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  &:hover {
    color: #f77b63;
  }
  @media (max-width: 1380px) {
    font-size: 15px;
  }
`;
const RotatingIcon = styled.img`
  transition: transform 0.2s ease;
`;
const DropDownHolder = styled.div`
  position: absolute;
  top: 15px;
  left: -20px;
  visibility: hidden;
  padding-top: 20px;
`;
const DropDownSection = styled.div`
  background-color: #fffbf8;
  border: 1px solid #f77b63;
  border-radius: 10px;
  opacity: 0.25;
  width: 400px;
  display: grid;
  transition: opacity 0.6s ease, transform 0.3s ease;
  @media (max-width: 575px) {
    opacity: 1;
    width: initial;
    margin-top: 15px;
  }
`;
const DropdownRow = styled.div`
  display: grid;
  /* grid-template-columns: 60px 1fr; */
  grid-template-columns: 1fr;
  gap: 22px;
  padding: 15px 20px;
  border-top: 1px solid #efddcf;
  @media (max-width: 575px) {
    /* grid-template-columns: 100px 1fr; */
    gap: 15px;
    padding: 15px;
  }
`;
const ProductsSection = styled.div`
  display: grid;
  grid-template-columns: auto 18px;
  gap: 3px;
  align-items: end;
  position: relative;
  font-weight: 500;
  &:hover {
    color: #f77b63;
  }
  &:hover ${RotatingIcon} {
    transform: rotate(180deg);
  }
  &:hover ${DropDownHolder} {
    visibility: visible;
  }
  &:hover ${DropDownSection} {
    opacity: 1;
    transform: translate(0px, 10px);
  }
`;
const SectionText = styled.p`
  color: #2d2d51;
  font-size: ${({ title }) => (title ? `16px` : `14px`)};
  font-weight: ${({ title }) => (title ? `600` : `500`)};
  @media (max-width: 575px) {
    font-size: ${({ title }) => (title ? `14px` : `12px`)};
  }
`;
const MobileView = styled.div`
  display: none;
  @media (max-width: 575px) {
    display: grid;
    gap: 25px;
    transition: max-height 0.6s ease;
    overflow: hidden;
  }
`;
const MobileProductsRow = styled.div`
  padding-top: 30px;
  display: grid;
  grid-template-columns: auto 18px;
  gap: 0 10px;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 500;
`;
const RotatingIconMobile = styled.img`
  transition: transform 0.2s ease;
  transform: ${({ showProducts }) =>
    showProducts ? `rotate(0deg)` : `rotate(-90deg)`};
`;
const ProductsSectionMob = styled.div`
  display: grid;
  gap: 20px;
  grid-column: 1 / span 2;
  transition: max-height 0.6s ease;
  overflow: hidden;
`;
const HamburgerSection = styled.div`
  position: fixed;
  top: 15px;
  right: 20px;
  display: none;
  cursor: pointer;
  @media (max-width: 575px) {
    display: flex;
    gap: 5px;
    flex-direction: column;
    justify-content: center;
    width: 26px;
    height: 26px;
  }
`;
const HamburgerStick = styled.div`
  width: 26px;
  height: 3px;
  border-radius: 6px;
  background-color: #2d2d51;
  transition: all 0.3s ease-in-out;
`;
const StickOne = styled(HamburgerStick)`
  transform: ${({ activeMobileNav }) =>
    activeMobileNav
      ? `rotate(45deg) translate(10px, 5px)`
      : `rotate(0deg) translate(0px, 0px)`};
`;
const StickTwo = styled(HamburgerStick)`
  transform: ${({ activeMobileNav }) =>
    activeMobileNav ? `translateX(-50px)` : `translateX(0px)`};
  background: ${({ activeMobileNav }) =>
    activeMobileNav ? `transparent` : `#2d2d51`};
  box-shadow: ${({ activeMobileNav }) =>
    activeMobileNav ? `none` : `0 2px 5px rgba(247 123 99 0.2)`};
  transition: transform 0.3s ease-in-out,
    ${({ activeMobileNav }) =>
      activeMobileNav
        ? `background 0.1s ease-in-out`
        : `background 0.4s ease-in-out`};
`;
const StickThree = styled(HamburgerStick)`
  transform: ${({ activeMobileNav }) =>
    activeMobileNav
      ? `rotate(-45deg) translate(6px, -2px)`
      : `rotate(0deg) translate(0px, 0px)`};
`;

export default ({}) => {
  const [activeMobileNav, setActiveMobileNav] = useState(false);
  const [setHeight, setHeightState] = useState("0px");
  const [showProducts, setShowProducts] = useState(false);
  const [productHeight, setProductHeight] = useState("0px");
  const content = useRef(null);
  const contentProducts = useRef(null);

  const toggleMobileNav = () => {
    setActiveMobileNav(!activeMobileNav);
    setHeightState(
      activeMobileNav ? "0px" : `${content.current.scrollHeight}px`
    );
  };
  const toggleProductMob = () => {
    setShowProducts(!showProducts);
    setProductHeight(
      showProducts ? "0px" : `${contentProducts.current.scrollHeight}px`
    );
    setHeightState(
      showProducts
        ? `${content.current.scrollHeight}px`
        : `${
            parseInt(content.current.scrollHeight) +
            parseInt(contentProducts.current.scrollHeight)
          }px`
    );
  };
  const closeMobileNav = (event) => {
    event.stopPropagation();
    setProductHeight("0px");
    setShowProducts(false);
    setHeightState("0px");
    setActiveMobileNav(false);
  };

  useEffect(() => {
    console.log(activeMobileNav, setHeight, showProducts, productHeight);
  }, [activeMobileNav, setHeight, showProducts, productHeight]);

  return (
    <Navbar>
      <LogoContainer>
        <StyledLink to="/">
          <StyledLargeImg src={logoWeb} alt="logo" />
          <StyledImg src={logo} alt="logo" />
        </StyledLink>
      </LogoContainer>
      <PageSection>
        <ProductsSection>
          <div>Products</div>
          <RotatingIcon src={arrowDown} alt="arrow down" width="100%" />
          <DropDownHolder>
            <DropDownSection>
              <StyledLink to="/segmently">
                <DropdownRow style={{ borderTop: `none` }}>
                  {/* <img
                    src={segmentlyImage}
                    alt="segmently thumbnail"
                    width="100%"
                  /> */}
                  <div>
                    <SectionText title>Segmently</SectionText>
                    <SectionText>
                      Design and run campaign in a minute.
                    </SectionText>
                  </div>
                </DropdownRow>
              </StyledLink>
              <StyledLink to="/about-us-shopify-app">
                <DropdownRow>
                  {/* <img
                    src={aboutUsImage}
                    alt="About Us thumbnail"
                    width="100%"
                  /> */}
                  <div>
                    <SectionText title>Our Story - About Us</SectionText>
                    <SectionText>
                      powerful tool to make your brand stand out.
                    </SectionText>
                  </div>
                </DropdownRow>
              </StyledLink>
              <StyledLink to="/ask-friends-shopify-app">
                <DropdownRow>
                  {/* <img
                    src={aboutUsImage}
                    alt="About Us thumbnail"
                    width="100%"
                  /> */}
                  <div>
                    <SectionText title>Ask Friends shopify app</SectionText>
                    <SectionText>
                      Allow your customers to ask their friends for purchase
                      advice and let them spread words about your brand on
                      Facebook, Twitter and email.
                    </SectionText>
                  </div>
                </DropdownRow>
              </StyledLink>
              <StyledLink to="/email-before-download-shopify-app">
                <DropdownRow>
                  {/* <img
                    src={aboutUsImage}
                    alt="About Us thumbnail"
                    width="100%"
                  /> */}
                  <div>
                    <SectionText title>Email Before Download</SectionText>
                    <SectionText>
                      Engage your store’s audiences with freebies or offers
                      using Email Before Download Shopify app.
                    </SectionText>
                  </div>
                </DropdownRow>
              </StyledLink>
            </DropDownSection>
          </DropDownHolder>
        </ProductsSection>
        <StyledLink to="/about">About Us</StyledLink>
        <StyledLink to="/contact">Contact Us</StyledLink>
      </PageSection>
      <HamburgerSection
        activeMobileNav={activeMobileNav}
        onClick={() => toggleMobileNav()}
      >
        <StickOne activeMobileNav={activeMobileNav} />
        <StickTwo activeMobileNav={activeMobileNav} />
        <StickThree activeMobileNav={activeMobileNav} />
      </HamburgerSection>
      <MobileView ref={content} style={{ maxHeight: `${setHeight}` }}>
        <MobileProductsRow onClick={() => toggleProductMob()}>
          <div>Products</div>
          <RotatingIconMobile
            showProducts={showProducts}
            src={arrowDown}
            alt="arrow down"
            width="100%"
          />
          <ProductsSectionMob
            ref={contentProducts}
            style={{ maxHeight: `${productHeight}` }}
          >
            <DropDownSection>
              <StyledLink to="/segmently" onClick={(e) => closeMobileNav(e)}>
                <DropdownRow style={{ borderTop: `none` }}>
                  <img
                    src={segmentlyImage}
                    alt="segmently thumbnail"
                    width="100%"
                  />
                  <div>
                    <SectionText title>Segmently</SectionText>
                    <SectionText>
                      Design and run campaign in a minute.
                    </SectionText>
                  </div>
                </DropdownRow>
              </StyledLink>
              {/* <StyledLink to="/" onClick={(e) => closeMobileNav(e)}>
                <DropdownRow>
                  <img
                    src={aboutUsImage}
                    alt="About Us thumbnail"
                    width="100%"
                  />
                  <div>
                    <SectionText title>Our Story - About Us</SectionText>
                    <SectionText>
                      powerful tool to make your brand stand out.
                    </SectionText>
                  </div>
                </DropdownRow>
              </StyledLink> */}
            </DropDownSection>
          </ProductsSectionMob>
        </MobileProductsRow>
        <StyledLink to="/about" onClick={(e) => closeMobileNav(e)}>
          About Us
        </StyledLink>
        <StyledLink
          to="/contact"
          onClick={(e) => closeMobileNav(e)}
          style={{ marginBottom: `20px` }}
        >
          Contact Us
        </StyledLink>
      </MobileView>
    </Navbar>
  );
};
