import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { StyledLargeImg, StyledImg, StyledLink, StyledA } from "./common";
import logoWeb from "../images/Logo-Appscom.svg";
import logo from "../images/favicon.png";

const Footer = styled.div`
  padding: 20px 30px;
  text-align: center;
  border-top: 1px solid #a5b4cb;
`;
const FooterUpperMain = styled.div`
  display: grid;
  grid-template-columns: 200px minmax(650px, 65%);
  justify-content: space-between;
  align-items: start;
  padding: 55px 10%;
  @media (max-width: 1100px) {
    grid-template-columns: 200px auto;
    gap: 30px;
    padding: 50px 6%;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    gap: 30px;
    padding: 30px;
  }
`;
const FooterUpperRight = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15%;
  justify-content: space-between;
  align-items: start;
  @media (max-width: 1100px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
    gap: 30px;
  }
`;
const ColumnSection = styled.div`
  display: grid;
  gap: 20px;
  width: max-content;
  @media (max-width: 575px) {
    gap: 16px;
  }
`;
const ColumnTitle = styled.p`
  color: #2d2d51;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: uppercase;
  @media (max-width: 575px) {
    margin-bottom: 5px;
  }
`;

export default ({}) => {
  return (
    <div>
      <FooterUpperMain>
        <div>
          <StyledA rel="noopener noreferrer" href="/">
            <StyledLargeImg src={logoWeb} alt="logo" />
            <StyledImg src={logo} alt="logo" />
          </StyledA>
        </div>
        <FooterUpperRight>
          <ColumnSection>
            <ColumnTitle>Products</ColumnTitle>
            <StyledA
              color="#59597B"
              fontSize="16px"
              fontSizeMobile="13px"
              rel="noopener noreferrer"
              href="/segmently"
            >
              Segmently
            </StyledA>
            <StyledA
              color="#59597B"
              fontSize="16px"
              fontSizeMobile="13px"
              rel="noopener noreferrer"
              href="/about-us-shopify-app"
            >
              About Us
            </StyledA>
            <StyledA
              color="#59597B"
              fontSize="16px"
              fontSizeMobile="13px"
              rel="noopener noreferrer"
              href="/ask-friends-shopify-app"
            >
              Ask Friends App
            </StyledA>
            <StyledA
              color="#59597B"
              fontSize="16px"
              fontSizeMobile="13px"
              rel="noopener noreferrer"
              href="/email-before-download-shopify-app"
            >
              Email Before Download App
            </StyledA>
          </ColumnSection>

          <ColumnSection>
            <ColumnTitle>TERMS</ColumnTitle>
            <StyledA
              color="#59597B"
              fontSize="16px"
              fontSizeMobile="13px"
              rel="noopener noreferrer"
              href="/privacy-policy/"
            >
              Privacy Policy
            </StyledA>
            <StyledA
              color="#59597B"
              fontSize="16px"
              fontSizeMobile="13px"
              rel="noopener noreferrer"
              href="/terms-condition"
            >
              Terms & Conditions
            </StyledA>
            <StyledA
              color="#59597B"
              fontSize="16px"
              fontSizeMobile="13px"
              rel="noopener noreferrer"
              href="/sitemap.xml"
            >
              Sitemap
            </StyledA>
          </ColumnSection>

          <ColumnSection>
            <ColumnTitle>SOCIAL</ColumnTitle>
            <StyledA
              color="#59597B"
              fontSize="16px"
              fontSizeMobile="13px"
              rel="noopener noreferrer"
              href="https://www.facebook.com/appscom.io"
            >
              Facebook
            </StyledA>
            <StyledA
              color="#59597B"
              fontSize="16px"
              fontSizeMobile="13px"
              rel="noopener noreferrer"
              href="https://twitter.com/appscom_io"
            >
              Twitter
            </StyledA>
          </ColumnSection>
          <div />
        </FooterUpperRight>
      </FooterUpperMain>
      <Footer>
        <p style={{ fontSize: `14px` }}>
          © {new Date().getFullYear()} Appscom – All Rights Reserved |
          support@appscom.io
        </p>
      </Footer>
    </div>
  );
};
