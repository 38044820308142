exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-us-shopify-app-js": () => import("./../../../src/pages/about-us-shopify-app.js" /* webpackChunkName: "component---src-pages-about-us-shopify-app-js" */),
  "component---src-pages-ask-friends-shopify-app-js": () => import("./../../../src/pages/ask-friends-shopify-app.js" /* webpackChunkName: "component---src-pages-ask-friends-shopify-app-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-email-before-download-shopify-app-js": () => import("./../../../src/pages/email-before-download-shopify-app.js" /* webpackChunkName: "component---src-pages-email-before-download-shopify-app-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-segmently-js": () => import("./../../../src/pages/segmently.js" /* webpackChunkName: "component---src-pages-segmently-js" */),
  "component---src-pages-shopify-apps-js": () => import("./../../../src/pages/shopify-apps.js" /* webpackChunkName: "component---src-pages-shopify-apps-js" */),
  "component---src-pages-terms-condition-js": () => import("./../../../src/pages/terms-condition.js" /* webpackChunkName: "component---src-pages-terms-condition-js" */)
}

